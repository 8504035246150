import { render, staticRenderFns } from "./PriorityGoodsQuantityCreate.vue?vue&type=template&id=02b3be56&scoped=true"
import script from "./PriorityGoodsQuantityCreate.vue?vue&type=script&lang=js"
export * from "./PriorityGoodsQuantityCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b3be56",
  null
  
)

export default component.exports